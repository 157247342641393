import widgetArea from '../widget/widget-area'
import widget from '../widget/widget'
import inlineImageWidget from '../widgets/general/inline-image'
import textWidget from '../widgets/general/text'
import downloadWidget from '../widgets/general/download'
import rateATrailerWidget from '../widgets/bbfc/rate-a-trailer'
import audioWidget from '../widgets/general/audio'
import videoWidget from '../widgets/general/video'
import htmlWidget from '../widgets/general/html'
import pollWidget from '../widgets/general/poll'
import faqWidget from '../widgets/general/faq'
import timelineWidget from '../widgets/bbfc/timeline'
import featuredDownload from '../widgets/general/featured-download'
import quizWidget from '../widgets/general/quiz'
import bioGridWidget from '../widgets/general/bio-grid'
import formWidget from '../widgets/bbfc/form'
import spotTheIssueWidget from '../widgets/bbfc/spot-the-issues'
import photoFormWidget from '../widgets/general/photo-form'

const widgets = [
  widget(),
  inlineImageWidget(),
  textWidget(),
  downloadWidget(),
  audioWidget(),
  rateATrailerWidget(),
  videoWidget(),
  pollWidget(),
  htmlWidget(),
  timelineWidget(),
  faqWidget(),
  featuredDownload(),
  quizWidget(),
  bioGridWidget(),
  formWidget(),
  spotTheIssueWidget(),
  photoFormWidget()
]

const createQuery = () => `
  body {
    ${widgetArea(widgets)}
  }
`
export default createQuery
