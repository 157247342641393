import renderResource from '../resource/render-resource'
import body from './body'
import relatedWidgets from './related-widgets'
import widgetArea from '../widget/widget-area'
import widget from '../widget/widget'
import articleLayoutHeader from '../widgets/general/article-layout-header'
import textWidget from '../widgets/general/text'
import standfirstWidget from '../widgets/general/standfirst'
import filmInformationWidget from '../widgets/general/film-information'
import articleDownloadWidget from '../widgets/general/article-download'
import relatedWidgetsWidget from '../widgets/general/related-widgets'
import lookOutForWidget from '../widgets/bbfc/look-out-for'
import featuredDownloadWidget from '../widgets/general/featured-download'
import pollWidget from '../widgets/general/poll'
import quizWidget from '../widgets/general/quiz'
import subNavigationWidget from '../widgets/general/sub-navigation'
import manualSubNavigationWidget from '../widgets/general/manual-sub-navigation'

const articleLayoutWidgets = [
  widget(),
  textWidget(),
  articleLayoutHeader(),
  standfirstWidget(),
  filmInformationWidget(),
  relatedWidgetsWidget(),
  lookOutForWidget(),
  featuredDownloadWidget(),
  relatedWidgetsWidget(),
  pollWidget(),
  articleDownloadWidget(),
  quizWidget(),
  subNavigationWidget(),
  manualSubNavigationWidget()
]

const createQuery = () => {
  const articleQuery = `...on Article {
    _id
    headline
    sell
    standfirst
    metaTitle
    metaDescription
    shareTitle
    shareDescription
    contentRating
    category
    displayDate
    articleType
    rateATrailerIssues
    rateATrailerVideoId
    rateATrailerVideoProvider
    rateATrailerOptions
    rateATrailerReasoning
    rateATrailerDidYouKnow
    spotTheIssueVideoProvider
    spotTheIssueVideoId
    spotTheIssueBehindTheClassification
    issues {
      type
      emoji
      description
    }
    timestamps {
      id
      timestamp
      issue
    }
    downloads {
      downloadUrl
      downloadSize
      downloadExtension
      name
    }
    images {
      hero_5x2_1920 {
        url
        caption
        link
      }
      hero_5x2_960 {
        url
        caption
        link
      }
      hero_5x2_550 {
        url
        caption
        link
      }
    }
    releases {
      directors
      releaseDate
      genre
      slug
    }
    section {
      _id
      darkModeEnabled
      layouts {
        article {
          layout {
            attributes
            cols {
              width
              widgetArea {
                ${widgetArea(articleLayoutWidgets)}
              }
            }
          }
        }
      }
    }
    ${body()}
    ${relatedWidgets()}
  }`
  return renderResource(articleQuery)
}

export default createQuery
