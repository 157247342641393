import widgetArea from '../widget/widget-area'
import widget from '../widget/widget'
import textWidget from '../widgets/general/text'
import relatedArticles from '../widgets/general/related-articles'

const widgets = [widget(), textWidget(), relatedArticles()]

const createQuery = () => `
  relatedWidgets {
    ${widgetArea(widgets)}
  }
`
export default createQuery
