import React from 'react'
import PropTypes from 'prop-types'
import retrieveAndProcessDataForPage from '@/lib/data-for-page-processor-and-retriever'
import createRenderArticleQuery from '@/query/article/render-article'
import dateFormatter from '@/lib/date-formatter'
import Layout from '@/component/Common/Layout'
import ContentMeta from '@/component/Meta/Content'
import BackgroundShapes from '@/component/Layout/BackgroundShapes'
import { RatingContextProvider } from '@/component/Context/RatingContext'

const ArticlePage = ({ article, section, instance, pageData }) => {
  if (!article) return null
  const layout =
    section &&
    section.layouts &&
    section.layouts.article &&
    section.layouts.article.layout

  const imageUrl =
    article &&
    article.images &&
    article.images.hero_5x2_1920 &&
    article.images.hero_5x2_1920[0] &&
    article.images.hero_5x2_1920[0].url
  return (
    <RatingContextProvider rating={article.contentRating}>
      <ContentMeta
        title={article.metaTitle || article.headline}
        description={article.metaDescription || article.sell}
        seoTitle={article.shareTitle || article.metaTitle || article.headline}
        seoDescription={
          article.shareDescription || article.metaDescription || article.sell
        }
        canonicalUrl={pageData.url}
        images={
          imageUrl
            ? [
                {
                  url: imageUrl,
                  width: 1920,
                  height: 768,
                  alt: article.headline
                }
              ]
            : null
        }
        publishedTime={dateFormatter(article.displayDate, 'ISO')}
        authorName="BBFC"
        publisherName="BBFC"
      />
      <BackgroundShapes />
      <Layout
        layout={layout}
        article={article}
        section={section}
        instance={instance}
        pageData={pageData}
      />
    </RatingContextProvider>
  )
}

ArticlePage.propTypes = {
  section: PropTypes.object,
  article: PropTypes.object,
  instance: PropTypes.object,
  pageData: PropTypes.object
}

ArticlePage.getInitialProps = async (context) => {
  const query = createRenderArticleQuery()
  const data = await retrieveAndProcessDataForPage(context, query)
  if (data && data.error) return data
  const article = data && data.response && data.response.resource
  const section = article && article.section
  const url = data && data.vars && data.vars.url
  const pageData = { url }
  return { ...data.response, article, section, pageData }
}

export default ArticlePage
